$(document).ready(function () {
  var mainTrustedSliderElement = $('.main-trusted .slider-container');
  UIkit.slider(mainTrustedSliderElement, {// velocity: 2
  }); //MAPPP

  (function () {
    var lookingId;
    var fromMap = false;

    function onItemShow(ev) {
      var id = $(ev.currentTarget).data('marker-id');

      if (!fromMap) {
        $('.main-map-marker').removeClass('is-active');
        var marker = $('.main-map-marker[data-id="' + id + '"]').addClass('is-active');
      }

      if (lookingId == id) {
        mainMapSliderElement.find('.slide').on('itemshow', onItemShow);
        lookingId = false;
        fromMap = false;
      }
    }

    var mainMapSliderElement = $('.main-map .slider-container');
    var markers = $('.main-map .main-map-marker');
    var slider = UIkit.slider(mainMapSliderElement, {});
    markers.on('click', function () {
      var id = $(this).data('id');
      $('.main-map-marker').removeClass('is-active');
      $(this).addClass('is-active');
      lookingId = id;
      fromMap = true;
      slider.show(id - 1);
    });
    mainMapSliderElement.find('.slide').on('itemshow', onItemShow);
  })();

  var mainSliderSliderElement = $('.main-slider .slider-container'); // UIkit.slideshow(mainSliderSliderElement, {
  //   animation: 'push'
  // });

  var additionalImage = $('.slide-additional-image');
  mainSliderSliderElement.find('.slide-1').on('itemshown', function (event) {
    additionalImage.addClass('is-visible');
  });
  mainSliderSliderElement.find('.slide-1').on('beforeitemhide', function (event) {
    additionalImage.removeClass('is-visible');
  }); // logos height hack

  var fadeLogos = $('.fade-logos').find('li');
  fadingLogos();

  function fadingLogos() {
    setInterval(function () {
      var fl = $('.fade-logos').find('li');
      fl.first().next('li').css('opacity', 0);
      fl.first().animate({
        opacity: 0
      }, 200, function () {
        $('.fade-logos li:last-child').after($(this));
        $('.fade-logos li:first-child').animate({
          opacity: 1
        }, 200);
      });
    }, 5000);
  }
  /* ABOUT US */


  var partnersSlider = $('.about .partners .slider-container');
  UIkit.slider(partnersSlider, {});

  (function () {
    var peopleMenuButtons = $('.about .people-menu a');
    var boardItemButtons = $('.about .people-board-item a');
    var closeButtons = $('.about .people-bio-close a');
    peopleMenuButtons.on('click', function () {
      var dataId = $(this).data('id');
      var currentBoard = $('.about .people-board[data-id="' + dataId + '"]');
      $('.about .people-board').removeClass('is-active');
      currentBoard.addClass('is-active');
      peopleMenuButtons.parent('li').removeClass('is-active');
      $(this).parent('li').addClass('is-active');
    });
    boardItemButtons.on('click', function () {
      $('.about .people-board-item').removeClass('is-active');
      $(this).closest('.people-board-item').addClass('is-active');
    });
  })();
  /** MENU */


  (function () {
    var header = $('.main-header');
    var mobileOpenBtn = header.find('.mobile-btn');
    var mobileCloseBtn = header.find('.mobile-top-close button');
    mobileOpenBtn.on('click', function () {
      header.find('nav').addClass('is-open');
      $('body').addClass('mobile-menu-is-open');
    });
    mobileCloseBtn.on('click', function () {
      header.find('nav').removeClass('is-open');
      $('body').removeClass('mobile-menu-is-open');
    });
  })();
  /** CAREERS */


  var vibeCareersSliderElement = $('.careers .vibe .slider-container');
  UIkit.slider(vibeCareersSliderElement, {// center: true
  });
  /** SERVICES */

  var servicesLearnMoreSliderElement = $('.services .learn .slider-container');
  UIkit.slider(servicesLearnMoreSliderElement, {// center: true
  });
  var servicesTrustedSliderElement = $('.services .trust .slider-container');
  UIkit.slider(servicesTrustedSliderElement, {// center: true
  }); // Accordion

  var clicableHeadlines = $('.services .tech-text-item > h5');
  clicableHeadlines.on('click', function () {
    var item = $(this).parent('.tech-text-item');

    if (item.hasClass('is-open')) {
      item.removeClass('is-open');
    } else {
      item.addClass('is-open');
    }
  }); // setTimeout(function(){
  //   $('.intro').addClass('animated');
  // }, 800)

  /** CONTACT PAGE */

  var legalInfoBtn = $('.contact .info-country span');
  legalInfoBtn.on('click', function () {
    var currentId = $(this).data('id');
    var currentAnchor = $('.contact .info-content[data-id="' + currentId + '"]');

    if ($(this).hasClass('is-active')) {
      return;
    }

    legalInfoBtn.removeClass('is-active');
    $('.contact .info-content').removeClass('is-active');
    $(this).addClass('is-active');
    currentAnchor.addClass('is-active');
  });
  /** FUNDS */

  /** c-selector-component */

  (function () {
    var docsContainer = $('.funds .funds-container');
    $('.c-selector-head').on('click', function () {
      var selector = $(this).parent('.c-selector');

      if (selector.hasClass('is-open')) {
        selector.removeClass('is-open');
      } else {
        selector.addClass('is-open');
      }
    });
    $('.c-selector ul a').on('click', function (ev) {
      ev.preventDefault();
      var selector = $(this).closest('.c-selector');
      var currentText = $(this).text();
      var id = $(this).data('id');
      var currentDoc = docsContainer.find('[data-id="' + id + '"]');
      selector.find('.c-selector-head span').text(currentText);
      selector.find('li.is-active').removeClass('is-active');
      $(this).parent('li').addClass('is-active');
      selector.removeClass('is-open');
      docsContainer.find('.funds-doc').addClass('is-hidden');
      currentDoc.removeClass('is-hidden');
    });
  })();
  /** VIDEO */


  var isYT = $('#youtube-video-container');
  var isVim = document.querySelector('#vimeo-video-container');

  if (isYT.length !== 0) {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    var videoId = isYT.data('id'); // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.

    var player;

    function onYouTubeIframeAPIReady() {
      player = new YT.Player('youtube-video-container', {
        width: 1280,
        height: 720,
        videoId: videoId
      });
    }

    $('.sgrid-video-mask').on('click', function () {
      player.playVideo();
      $(this).addClass('is-clicked');
    });
  }

  if (isVim) {
    $('.sgrid-video-mask').on('click', function () {
      var player = new Vimeo.Player(isVim);
      player.play();
      $(this).addClass('is-clicked');
    });
  } //COOKIES


  (function () {
    function setCookie(key, value) {
      var expires = new Date();
      expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
      document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
    }

    function getCookie(key) {
      var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
      return keyValue ? keyValue[2] : null;
    }

    var acceptance = getCookie('policy-acceptance-ardigen');
    var cookiesBox = $('.main-slider .cookies');

    if (acceptance) {
      $('.main-slider .slide-content').addClass('cookies-accepted');
    } else {
      cookiesBox.removeClass('is-hidden');
      $('.main-slider .slide-content').addClass('cookies-not-accepted');
    }

    var cookiesBtn = $('.main-slider .cookies-button button');
    cookiesBtn.on('click', function () {
      setCookie('policy-acceptance-ardigen', true);
      cookiesBox.addClass('is-hidden');
      $('.main-slider .cookies-not-accepted').removeClass('cookies-not-accepted');
    });
  })(); // // FORM FILE UPLOAD
  // var fileInput = $('.careers .contact form .contact-form-upload-box input');
  // var fileBox = $('.careers .contact form .contact-form-upload-box');
  // var fileBoxAlert = $('.careers .contact form .contact-form-upload-box .alert');
  // fileInput.on('change', function(){
  //   fileBoxAlert.addClass('is-hidden');
  //   var files = $(this).get(0).files;
  //   fileBox.find('ul').remove();
  //   var cont = $('<ul></ul>');
  //   if(files.length >5 ){
  //     fileBoxAlert.removeClass('is-hidden');
  //     fileInput.val('');
  //     // return;
  //   }
  //   for(var x=0; x<files.length;x++){
  //     var f = files[x];
  //     var fOb = $('<li uk-scrollspy="cls:uk-animation-slide-right-medium">'+ f.name + '</li>');
  //     cont.append(fOb);
  //   }
  //   fileBox.prepend(cont);
  // })


  (function () {
    function clearItem(element) {
      element.find('.upload-file-loaded-container').remove();
      element.find('input').val('');
    }

    var fileInputs = $('.careers .contact form .contact-form-upload-box input');
    var fileInputsWraps = $('.careers .contact form .contact-form-upload-box .upload-fields-lancher');
    var fileInputsMask = $('.careers .contact form .contact-form-upload-box .upload-fields-mask');
    var maxSizeError = $('.careers .contact form .upload-fields').data('error');
    fileInputsMask.on('click', function (e) {
      var input = $(this).closest('.upload-fields').find('input').first();
      $(this).remove();
      input.trigger('click');
    });
    fileInputsWraps.on('click', function (e) {
      var input = $(this).closest('.upload-fields-item').find('input');
      input.trigger('click');
    });
    fileInputs.on('change', function () {
      var file = $(this).get(0).files;
      var parentItem = $(this).closest('.upload-fields-item');
      var container = $('<div class="upload-file-loaded-container"></div>');

      if (file.length === 0) {
        // console.log('nie wybrano / usunieto plik');
        return;
      }

      file = file[0];

      if (file.size > 2097152) {
        // if(file.size > 10){
        clearItem(parentItem);
        var error = $('<span class="upload-label-error">' + maxSizeError + '</span>');
        container.append(error);
        parentItem.append(container);
        return;
      }

      var name = $('<span class="upload-label-name" zero-jeden>' + file.name + '</span>');
      var del = $('<button class="upload-btn-delete" zero-jeden>x</button>');
      del.css({
        position: 'relative',
        zIndex: 135
      });
      del.on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var control = $(this).closest('.upload-fields-item');
        clearItem(control);
      });
      container.append(name).append(del);
      parentItem.append(container);
    });
  })();
}); //RECAPTCHA HACK

var rc = document.querySelector('.g-recaptcha');
$(rc).attr('data-callback', 'onReCaptchaSuccess');
var HEADER_HEIGHT = 0; // Height of header/menu fixed if exists

var isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
var grecaptchaPosition;

var isScrolledIntoView = function (elem) {
  var elemRect = elem.getBoundingClientRect();
  var isVisible = elemRect.top - HEADER_HEIGHT >= 0 && elemRect.bottom <= window.innerHeight;
  return isVisible;
};

if (isIOS) {
  var recaptchaElements = document.querySelectorAll('.g-recaptcha');
  window.addEventListener('scroll', function () {
    Array.prototype.forEach.call(recaptchaElements, function (element) {
      if (isScrolledIntoView(element)) {
        grecaptchaPosition = document.documentElement.scrollTop || document.body.scrollTop;
      }
    });
  }, false);
}

var onReCaptchaSuccess = function () {
  if (isIOS && grecaptchaPosition !== undefined) {
    window.scrollTo(0, grecaptchaPosition);
  }
};

var element = null;
$(document).on('click', '.toggler', function (event) {
  event.preventDefault();

  if (element == null) {
    $(this).closest('section').next().find('.open').stop().animate({
      height: '0'
    }, animateTime, 'linear');
    element = this.dataset.toggle;
  } else {
    if ($(this).closest('section').next().find('.open').attr('id') != this.dataset.toggle) {
      $(this).closest('section').next().find('.open').stop().animate({
        height: '0'
      }, animateTime, 'linear');
    }

    var growDiv = $('#' + element);
    growDiv.stop().animate({
      height: '0'
    }, animateTime, 'linear');
    element = this.dataset.toggle;
  }

  var animateTime = 500;
  var growDiv = $('#' + element);

  if (growDiv.height() === 0) {
    autoHeightAnimate(growDiv, animateTime);
  } else {
    growDiv.stop().animate({
      height: '0'
    }, animateTime, 'linear');
  }
});
/* Function to animate height: auto */

function autoHeightAnimate(element, time) {
  var curHeight = element.height(),
      // Get Default Height
  autoHeight = element.css('height', 'auto').height(); // Get Auto Height

  element.height(curHeight); // Reset to Default Height

  element.stop().animate({
    height: autoHeight
  }, time); // Animate to Auto Height

  var y = $(window).scrollTop() + 250; //your current y position on the page

  var offset = element.offset().top - 50;
  $('html, body').animate({
    scrollTop: y
  }, 600);
}
/** VIDEO */


var isYTHome = $('#video-modal');
console.log(isYTHome);

if (isYTHome.length !== 0) {
  var tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  var firstScriptTag = document.getElementsByTagName('script')[0];
  console.log(tag);
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  var videoId = isYTHome.data('id'); // 3. This function creates an <iframe> (and YouTube player)
  //    after the API code downloads.

  var player;

  window.onYouTubeIframeAPIReady = function () {
    player = new YT.Player('video-modal', {
      width: 1280,
      height: 720,
      videoId: videoId // events: {
      //   'onReady': onPlayerReady,
      // }

    });
  };

  function onPlayerReady(event) {
    event.target.playVideo();
  }

  $('#open-yt').on('click', function () {
    player.playVideo();
  });
  UIkit.util.on(document, 'hide', '#modal-yt', function () {
    player.stopVideo();
  });
} // NEW CODE


function countUpElements(event) {
  var counted = false;

  if (!counted) {
    UIkit.util.on('#counters', 'inview', function () {
      $('.counter__number').each(function () {
        var $this = $(this),
            countTo = $this.attr('data-count');
        $({
          countNum: $this.text()
        }).animate({
          countNum: countTo
        }, {
          duration: 3000,
          easing: 'linear',
          step: function () {
            $this.text(Math.floor(this.countNum));
          },
          complete: function () {
            $this.text(this.countNum);
          }
        });
      });
    });
    counted = true;
  }
}

var learnMoreContainer = document.querySelector('.learn-more'); // var accordionsContainer = document.querySelector('.accordions__container');

import HandyCollapse from 'handy-collapse';
new HandyCollapse({
  animationSpeed: learnMoreContainer ? 450 : 150,
  onSlideStart: (isOpen, contentID) => {
    if (isOpen) {
      const contentEl = document.querySelector(`[data-hc-content='${contentID}']`);
      const buttonEl = document.querySelector(`[data-hc-control='${contentID}']`); // const leftPosition = buttonEl.getClientRects()[0].left;

      const leftPosition = buttonEl.getBoundingClientRect().left;
      const sumPositions = (window.innerWidth - document.body.clientWidth) / 2;
      contentEl.style.transform = `translateX(-${leftPosition + sumPositions}px)`;
    }
  }
});

function attachFileToHiddenCF() {
  if ($('.leaflet').length > 0) {
    $('input[name="your-file"]').val($('[data-file]').data('file'));
  }
}

function restrictedBoxes() {
  if ($('.flip-card').length > 0) {
    var unlocked = localStorage.getItem('unlocked');
    $('.btn__more').on('click', function () {
      $(this).toggleClass('less');
      var text = $(this).text();
      $(this).text(text == $(this).data('less') ? $(this).data('more') : $(this).data('less'));
    });

    function enableAccessToWebinar() {
      $('.webinar').each(function (index) {
        var $url = $(this).find('.flip-card-back').data('fileurl');
        var $text = $(this).find('.webinar_button').text();
        $(this).find('.webinar_button').remove();
        $(this).find('.flip-card-front').append('<a href="' + $url + '" target="_blank" class="btn btn__with-arrow btn__default">' + $text + ' </a>');
      });
    }

    if (unlocked) {
      enableAccessToWebinar();
    }

    function returnDownloadText(url) {
      return '<span class="flip-card__title">If the download does not start automatically in a few seconds, please <a href="' + url + '">click here</a> to access the download URL directly.</span>';
    }

    function returnWebinarText(url) {
      return '<span class="flip-card__title">If the link does not open automatically in a few seconds, please <a href="' + url + '">click here</a> to access the link URL directly.</span>';
    }

    $('.flip-card__button').on('click', function () {
      var $button = $(this);
      var $box = $(this).closest('.flip-card');
      $box.addClass('flipped'); // $file_url = $closest

      var wpcf7Elm = $box.find('.wpcf7')[0];
      var current_title = $box.find('.flip-card__title').text();
      var current_url = $box.find('*[data-fileurl]')[0];
      $box.find('input[name=post-title]').val(current_title); //

      if (wpcf7Elm) {
        wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
          window.open(current_url.dataset.fileurl);
          $(wpcf7Elm).hide();

          if ($button.hasClass('webinar_button')) {
            localStorage.setItem('unlocked', true);
            enableAccessToWebinar();
            $box.find('.flip-card-back').append(returnWebinarText(current_url.dataset.fileurl));
          } else {
            $box.find('.flip-card-back').append(returnDownloadText(current_url.dataset.fileurl));
          }

          $box.find('.flip-card-back__title').addClass('flip-card__date');
        }, false);
      }
    });
  }
}

$(document).ready(function () {
  // TO DO add rule if is homepage
  $('.uk-accordion-title').on('click', function () {
    var scrollEl = $(this); // if (!$(this).parent().hasClass('uk-open')) {

    setTimeout(function () {
      var $scrolling = UIkit.scroll({
        duration: 600,
        offset: 100
      });
      $scrolling.scrollTo(scrollEl);
    }, 220); // }
  });
  $(window).scroll(function () {
    if ($(window).scrollTop() + $(window).height() > $(document).height() / 2) {
      $('.go-up').addClass('show');
    } else {
      $('.go-up').removeClass('show');
    }
  });
  attachFileToHiddenCF();
  countUpElements();
  restrictedBoxes();
  $('.menu-item-has-children > a').on('click', function (e) {
    e.preventDefault();
    $(this).parent().toggleClass('is-selected');
  });
});
/** Career response **/

var contactCareerContainer = document.querySelector('.contact-career');

if (contactCareerContainer) {
  var messageSent = contactCareerContainer.querySelector('.message-sent');
  var wpcf7Elm = contactCareerContainer.querySelector('.wpcf7');
  wpcf7Elm.addEventListener('wpcf7submit', function (event) {
    messageSent.classList.add('is-active');
  }, false);
  contactCareerContainer.querySelector('.message-sent__close').addEventListener('click', () => {
    var reponseMessage = contactCareerContainer.querySelector('.wpcf7-response-output');
    messageSent.classList.remove('is-active');
    reponseMessage.textContent = '';
    reponseMessage.style.display = 'none';
  });
}